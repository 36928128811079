// If you want to override variables do it here
@import "variables";

$enable-ltr: true;
$enable-rtl: true;

// Import styles
@import "@coreui/coreui/scss/coreui";

@import "layout";
@import "example";


.carousel .thumb img {
  width: 100% !important;
  height: 100% !important;
}

.carousel .slide img {
  max-height: 300px;  /* change this to whatever you want */
  width: auto;
}
